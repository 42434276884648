<template>
    <div v-if="article">
        <div
            class="imageholder"
            v-bind:class="{ 
                'img-1-1': article.club.featuredMediaFormat == '1:1',
                'img-16-9': article.club.featuredMediaFormat == '16:9',
                'img-3-2': article.club.featuredMediaFormat == '3:2',
                'img-4-3': article.club.featuredMediaFormat == '4:3'
            }"
            v-bind:style="{ backgroundImage: `url(${cardImage})` }"
        >
            <div class="cal-box">
                <h2>{{ date.day }}</h2>
                <ion-text>{{ date.month }}</ion-text>
            </div>
            <div class="img-title">
                <ion-item lines="none" color="none" class="title-gradient">
                    <ion-icon
                        v-if="article.mediaChannel == 'website'"
                        size="large"
                        :icon="newspaper"
                    ></ion-icon>
                    <ion-icon
                        v-if="article.mediaChannel == 'twitter'"
                        size="large"
                        :icon="logoTwitter"
                    ></ion-icon>
                    <ion-icon
                        v-if="article.mediaChannel == 'facebook'"
                        size="large"
                        :icon="logoFacebook"
                    ></ion-icon>
                    <ion-icon
                        v-if="article.mediaChannel == 'instagram'"
                        size="large"
                        :icon="logoInstagram"
                    ></ion-icon>
                    <ion-text>
                        <p class="ion-text-center">{{ club.name }}</p>
                    </ion-text>
                    <ion-thumbnail slot="end">
                        <img :src="article.club.branding.logoUrl" />
                    </ion-thumbnail>
                </ion-item>
            </div>
        </div>

        <!--     <ion-img :src="featuredMediaUrl" :alt="title"></ion-img> -->
        <div class="ion-padding">
            <h2 class="ion-text-start" v-html="article.title"></h2>
            <small>Datum: {{ date.pubDate }}</small>
            <br />
            <small>Kategorie: {{ localCategories }}</small>
            <p class="ion-text-start text-excerpt" v-html="article.excerpt"></p>
            <!--         <p class="ion-text-justify">{{ content }}</p> -->
            <div id="cms-content" v-html="article.content"></div>

            <ion-text class>
                Quelle:
                <br />
                {{ article.externalLink }}
            </ion-text>
        </div>
    </div>
</template>

<script lang="ts">
import { IonText, IonIcon, IonItem, IonThumbnail } from "@ionic/vue";
import { logoTwitter, logoFacebook, logoInstagram, newspaper, heartOutline, heart, notifications, shareSocial } from "ionicons/icons";

import { News } from "@/types/News/News";

import { defineComponent, PropType, computed } from 'vue';
export default defineComponent({
    props: {
        article: {
            type: Object as PropType<News>,
            required: true
        },
    },
    components: {
        IonIcon,
        IonItem,
        IonText,
        IonThumbnail,
    },
    setup(props) {

        const date: any = computed(() => {
            return {
                day: new Date(props.article.postCreatedAt).toLocaleDateString("de-DE", { day: "2-digit" }),
                month: new Date(props.article.postCreatedAt).toLocaleDateString("de-DE", { month: "short" }),
                pubDate: new Date(props.article.postCreatedAt).toLocaleDateString('de-DE', { dateStyle: 'long' })
            };
        });

        const localCategories = computed(() => {
            let tempCategories: string[] = [];
            if (props.article?.categories) {
                props.article?.categories.forEach(element => {
                   tempCategories.push(element.name);
                });
            }
            return tempCategories.join().replace(/,/g, ", ");
        });

        const club = computed(() => {      
            return props.article?.club
        });

        const cardImage = computed(() => {
            return (props.article?.featuredMediaUrl != '' && props.article?.featuredMediaUrl != null) ? props.article?.featuredMediaUrl : club.value.branding.logoUrl
        })

        return {
            logoTwitter,
            logoFacebook,
            logoInstagram,
            newspaper,
            heartOutline,
            heart,
            notifications,
            shareSocial,
            date,
            localCategories,
            club,
            cardImage
        }
    }

})
</script>

<style>
div.ion-padding > h2 {
    margin-top: 0px;
}

.imageholder {
    position: relative;
    width: 100%;
    padding-bottom: 56.75%;
    background-size: cover;
    background-position: center center;
}

.img-1-1 {
    padding-bottom: 100%;
}

.img-16-9 {
    padding-bottom: 56.75%;
}

.img-3-2 {
    padding-bottom: 66.6667%;
}

.img-4-3 {
    padding-bottom: 75%;
}

.cal-box {
    position: absolute;
    top: 1rem;
    left: 1rem;
    border: var(--ion-color-primary-contrast) solid 1px;
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    border-radius: 0.5rem;
    padding: 0;
    height: 3rem;
    width: 3rem;
    text-align: center;
    font-size: 14px;
}

.cal-box > h2 {
    margin-top: 0.125rem;
    margin-bottom: -0.125rem;
    font-size: 1.25rem;
    font-weight: bold;
}

.cal-box > ion-text {
    font-family: var(--ion-font-family-medium);
    font-size: 0.75rem;
    line-height: 100%;
}

.img-title {
    position: absolute;
    bottom: 0;
    width: 100%;
    /*     left: 2rem;
 */
}

.img-title ion-card-subtitle {
    margin-left: auto;
    margin-right: auto;
}

.img-title ion-text {
    width: 100%;
    color: var(--ion-color-primary-contrast);
    font-family: var(--ion-font-family-semi-bold);
    line-height: 100%;
}

.title-gradient {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.75) 0%,
        rgba(0, 0, 0, 0.5) 60%,
        rgba(0, 0, 0, 0) 100%
    );
}

ion-item.title-gradient > ion-icon {
    color: var(--ion-color-primary-contrast);
    margin-right: 8px;
}

ion-item > ion-thumbnail {
    --border-radius: 25%;
    border: 1px var(--ion-color-primary) solid;
    width: 2rem;
    height: 2rem;
    background: var(--ion-color-primary-contrast);
}

div#cms-content ol,
ul {
    padding-inline-start: 24px;
}

ion-icon {
    color: var(--ion-color-primary);
}

.text-excerpt {
    font-family: var(--ion-font-family);
    font-style: italic;
}
</style>