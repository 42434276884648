
import { IonText, IonIcon, IonItem, IonThumbnail } from "@ionic/vue";
import { logoTwitter, logoFacebook, logoInstagram, newspaper, heartOutline, heart, notifications, shareSocial } from "ionicons/icons";

import { News } from "@/types/News/News";

import { defineComponent, PropType, computed } from 'vue';
export default defineComponent({
    props: {
        article: {
            type: Object as PropType<News>,
            required: true
        },
    },
    components: {
        IonIcon,
        IonItem,
        IonText,
        IonThumbnail,
    },
    setup(props) {

        const date: any = computed(() => {
            return {
                day: new Date(props.article.postCreatedAt).toLocaleDateString("de-DE", { day: "2-digit" }),
                month: new Date(props.article.postCreatedAt).toLocaleDateString("de-DE", { month: "short" }),
                pubDate: new Date(props.article.postCreatedAt).toLocaleDateString('de-DE', { dateStyle: 'long' })
            };
        });

        const localCategories = computed(() => {
            let tempCategories: string[] = [];
            if (props.article?.categories) {
                props.article?.categories.forEach(element => {
                   tempCategories.push(element.name);
                });
            }
            return tempCategories.join().replace(/,/g, ", ");
        });

        const club = computed(() => {      
            return props.article?.club
        });

        const cardImage = computed(() => {
            return (props.article?.featuredMediaUrl != '' && props.article?.featuredMediaUrl != null) ? props.article?.featuredMediaUrl : club.value.branding.logoUrl
        })

        return {
            logoTwitter,
            logoFacebook,
            logoInstagram,
            newspaper,
            heartOutline,
            heart,
            notifications,
            shareSocial,
            date,
            localCategories,
            club,
            cardImage
        }
    }

})
