<template>
  <base-details-layout
    :page-title="title"
    page-default-back-link="/news"
  >
    <news-details v-if="article" :article="article"></news-details>
  </base-details-layout>
</template>

<script lang="ts">
import BaseDetailsLayout from "../../../components/base/BaseDetailsLayout.vue";
import NewsDetails from "./components/NewsDetails.vue";


import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { defineComponent, computed } from 'vue';
export default defineComponent({
  components: {
    BaseDetailsLayout,
    NewsDetails,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    //store.dispatch("news");

    const article = computed(() => {
      return store.getters.article(route.params.uuid);
    });

    return {
      title: article.value.title,
      article
    }
  }
});
</script>

<style scoped>
</style>